<template>
  <div class="page">
    <div class="topnews" v-if="newslist[3]">
      <div class="h1" @click="newsLink(newslist[0], 1)">
        {{ newslist[0].title }}
      </div>
      <div class="bottom">
        <p>
          <i class="el-icon-discover"></i>&ensp;<a
            @click="newsLink(newslist[1], 1)"
            >{{ newslist[1].title }}</a
          >
        </p>
        <p>
          <i class="el-icon-discover"></i>
          &ensp;<a @click="newsLink(newslist[2], 1)">{{ newslist[2].title }}</a>
        </p>
        <p>
          <i class="el-icon-discover"></i>&ensp;<a
            @click="newsLink(newslist[3], 1)"
            >{{ newslist[3].title }}</a
          >
        </p>
      </div>
    </div>
    <div class="body clearfix">
      <div class="left">
        <div class="top">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span class="title"
                ><i class="el-icon-document"></i>&ensp;企业新闻</span
              >
              <el-button
                style="float: right; padding: 3px 0"
                type="text"
                @click="$router.push('/message')"
                >查看更多 <i class="el-icon-d-arrow-right"></i
              ></el-button>
            </div>
            <div class="con clearfix">
              <!-- 左侧轮播 -->
              <div class="c-left">
                <div class="carousel">
                  <el-carousel
                    height="200px"
                    arrow="never"
                    direction="vertical"
                  >
                    <el-carousel-item v-for="item in imglist" :key="item.id">
                      <div class="box" @click="newsLink(item)">
                        <img :src="item.imgUrl" width="100%" height="100%" />
                        <p>{{ item.title }}</p>
                      </div>
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </div>
              <!-- 左侧轮播 -->
              <!-- 右侧文字 -->
              <div class="c-right">
                <!-- 每条数据 -->
                <div
                  class="c-text clearfix"
                  v-for="item in newslist"
                  :key="item.id"
                >
                  <div class="c-text-left" @click="newsLink(item)">
                    <i class="el-icon-info"></i>
                    {{ item.title }}
                  </div>
                  <div class="c-text-right">
                    {{ item.lastUpdateTime.slice(0, 11) }}
                  </div>
                </div>
                <!-- 每条数据 -->
              </div>
              <!-- 右侧文字 -->
            </div>
          </el-card>
        </div>
        <div class="middle clearfix">
          <div class="m-left">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span class="title"
                  ><i class="el-icon-reading"></i>&ensp;法律法规</span
                >
                <el-button
                  style="float: right; padding: 3px 0"
                  type="text"
                  @click="$router.push('/serve')"
                  >查看更多 <i class="el-icon-d-arrow-right"></i
                ></el-button>
              </div>
              <!-- 每条数据 -->
              <div
                class="m-text clearfix"
                v-for="item in lawslist"
                :key="item.id"
              >
                <div class="m-text-left" @click="lawsLink(item)">
                  <i class="el-icon-info"></i>
                  {{ item.title }}
                </div>
                <div class="m-text-right">
                  {{ item.lastUpdateTime.slice(0, 11) }}
                </div>
              </div>
              <!-- 每条数据 -->
            </el-card>
          </div>
          <div class="m-right">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span class="title"
                  ><i class="el-icon-notebook-1"></i>&ensp;用水知识</span
                >
                <el-button
                  style="float: right; padding: 3px 0"
                  type="text"
                  @click="$router.push('/serve')"
                  >查看更多 <i class="el-icon-d-arrow-right"></i
                ></el-button>
              </div>
              <!-- 每条数据 -->
              <div
                class="m-text clearfix"
                v-for="item in knowledgelist"
                :key="item.id"
              >
                <div class="m-text-left" @click="knowLink(item)">
                  <i class="el-icon-info"></i>
                  {{ item.title }}
                </div>
                <div class="m-text-right">
                  {{ item.lastUpdateTime.slice(0, 11) }}
                </div>
              </div>
              <!-- 每条数据 -->
            </el-card>
          </div>
        </div>
        <div class="bottom">
          <el-tabs value="one" type="border-card">
            <el-tab-pane
              v-for="item in noticelist"
              :key="item.id"
              :label="item.title"
              :name="item.name"
            >
              <div class="b-con clearfix">
                <!-- 每条数据 -->
                <div class="b-text" v-for="each in item.list" :key="each.id">
                  <div class="b-text-left" @click="noticeLink(each)">
                    <i class="el-icon-info"></i>
                    {{ each.title }}
                  </div>
                  <div class="b-text-right">
                    {{ each.lastUpdateTime.slice(0, 11) }}
                  </div>
                </div>
                <!-- 每条数据 -->
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div class="right">
        <div class="business">
          <div class="top" @click="goOline()">
            <img src="../../assets/img/businesshall.jpg" width="100%" />
          </div>
          <div class="icon clearfix" @click="goOline()">
            <div class="item" v-for="(item, index) in iconlist" :key="index">
              <img :src="item.url" width="100%" />
              <p>{{ item.name }}</p>
            </div>
          </div>
          <div class="service">
            <img src="../../assets/img/service.jpg" width="100%" />
          </div>
          <div class="map">
            <p>服务网点</p>
            <img src="../../assets/img/map.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="link">
      <el-divider></el-divider>
      <div class="links clearfix">
        <div class="text">友情链接</div>
        <img
          v-for="item in linklist"
          :key="item.id"
          :src="item.imgUrl"
          class="img"
          @click="friendlyLink(item)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  GetImgList,
  GetLinkList,
  GetMsgList,
  GetNoticeList,
  GetServeList,
} from "@/api/home";

export default {
  name: "Index",
  data() {
    return {
      newslist: [],
      imglist: [],
      lawslist: [],
      knowledgelist: [],
      noticelist: [],
      linklist: [],
      iconlist: [
        { name: "历史账单", url: require("../../assets/img/icon/icon1.png") },
        { name: "水费查询", url: require("../../assets/img/icon/icon2.png") },
        { name: "投诉建议", url: require("../../assets/img/icon/icon3.png") },
        { name: "线上报修", url: require("../../assets/img/icon/icon4.png") },
        { name: "用户报装", url: require("../../assets/img/icon/icon5.png") },
        { name: "合同新签", url: require("../../assets/img/icon/icon6.png") },
      ],
    };
  },
  created() {
    this.getNewsList();
    this.getNewsImg();
    this.getLawsList();
    this.getKnowList();
    this.getNoticeList();
    this.getLink();
  },
  methods: {
    //跳转网上营业厅
    goOline() {
      window.open("http://www.dzszlsyxgs.com:8081");
    },
    //新闻模块跳转
    newsLink(item) {
      //console.log(item);
      this.$router.push({
        path: "/message",
        query: { fid: item.categoryId, id: item.id },
      });
    },
    //法律法规模块跳转
    lawsLink(item) {
      //console.log(item);
      this.$router.push({
        path: "/serve",
        query: { fid: item.categoryId, id: item.id },
      });
    },
    //用水知识模块跳转
    knowLink(item) {
      //console.log(item);
      this.$router.push({
        path: "/serve",
        query: { fid: item.categoryId, id: item.id },
      });
    },
    //三个公告模块跳转
    noticeLink(item) {
      //console.log(item);
      this.$router.push({
        path: "/notice",
        query: { fid: item.categoryId, id: item.id },
      });
    },
    //获取三个公告
    async getNoticeList() {
      let stopNotice = await GetNoticeList({ cid: 15, p: 1 });
      let qualityNotice = await GetNoticeList({ cid: 16, p: 1 });
      let notNotice = await GetNoticeList({ cid: 17, p: 1 });
      this.noticelist = [
        {
          title: "停水公告",
          id: 0,
          name: "one",
          list: stopNotice.data.data.data.slice(0, 6),
        },
        {
          title: "水质公告",
          id: 1,
          name: "two",
          list: qualityNotice.data.data.data.slice(0, 6),
        },
        {
          title: "通知公告",
          id: 2,
          name: "three",
          list: notNotice.data.data.data.slice(0, 6),
        },
      ];
      //console.log(this.noticelist);
    },
    //获取用水知识列表
    getKnowList() {
      let data = { cid: 24, p: 1 };
      GetServeList(data)
        .then((res) => {
          //console.log(res);
          this.knowledgelist = res.data.data.data.slice(0, 7);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取法律法规列表
    getLawsList() {
      let data = { cid: 22, p: 1 };
      GetServeList(data)
        .then((res) => {
          //console.log(res);
          this.lawslist = res.data.data.data.slice(0, 7);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取企业新闻图片列表
    getNewsImg() {
      let data = { cid: 13 };
      GetImgList(data)
        .then((res) => {
          //console.log(res);
          this.imglist = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取企业新闻列表
    getNewsList() {
      let data = { cid: 13, p: 1 };
      GetMsgList(data)
        .then((res) => {
          // console.log(res);
          let news = res.data.data.data.slice(0, 7);
          this.newslist = news;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取友情链接
    getLink() {
      let data = { p: 1 };
      GetLinkList(data)
        .then((res) => {
          //console.log(res);
          this.linklist = res.data.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //友情链接点击
    friendlyLink(item) {
      //console.log(item);
      if (item.url) {
        window.open(item.url);
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  .topnews {
    width: 100%;
    height: 135px;
    margin-bottom: 15px;
    padding: 24px 48px;
    background: url(../../assets/img/bg-new.png) #f5f5f5 left top no-repeat;
    .h1 {
      width: 100%;
      font-size: 32px;
      font-weight: 600;
      line-height: 55px;
      color: #d51200;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
    .bottom {
      width: 100%;
      p {
        text-align: center;
        font-size: 14px;
        color: #5e5e5e;
        float: left;
        width: 33%;
        padding: 0 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 32px;
        a {
          cursor: pointer;
        }
      }
    }
  }
  .body {
    width: 100%;
    .left {
      float: left;
      width: 71%;
      .top {
        margin-bottom: 15px;
        .con {
          .c-left {
            float: left;
            width: 320px;
            height: 200px;
            .carousel {
              width: 100%;
              height: 100%;
              .box {
                cursor: pointer;
                width: 100%;
                height: 100%;
                position: relative;
                p {
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  width: 100%;
                  line-height: 2.5;
                  color: #fff;
                  background-color: rgba(0, 0, 0, 0.5);
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  padding: 0 10px;
                }
              }
            }
          }
          .c-right {
            float: right;
            width: 475px;
            .c-text {
              line-height: 30px;
              cursor: pointer;
              .c-text-left {
                width: 385px;
                float: left;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                i {
                  color: #666666;
                }
              }
              .c-text-left:hover {
                color: #006ebc;
              }
              .c-text-right {
                float: right;
                color: #666666;
              }
            }
          }
        }
      }
      .middle {
        margin-bottom: 15px;
        .m-left {
          float: left;
          width: 49%;
          .box-card {
            min-height: 300px;
          }
        }
        .m-right {
          float: right;
          width: 49%;
          .box-card {
            min-height: 300px;
          }
        }
        .m-text {
          line-height: 30px;
          cursor: pointer;
          .m-text-left {
            width: 285px;
            float: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            i {
              color: #666666;
            }
          }
          .m-text-left:hover {
            color: #006ebc;
          }
          .m-text-right {
            float: right;
            color: #666666;
          }
        }
      }
      .bottom {
        width: 100%;
        .b-con {
          height: 115px;
          .b-text {
            padding: 0 15px;
            float: left;
            width: 50%;
            line-height: 35px;
            cursor: pointer;
            .b-text-left {
              width: 285px;
              float: left;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              i {
                color: #666666;
              }
            }
            .b-text-right {
              float: right;
              color: #666666;
            }
            .b-text-left:hover {
              color: #006ebc;
            }
          }
        }
      }
    }
    .right {
      float: right;
      width: 28%;
      // background-color: #f8f8f8;
      height: 500px;
      .business {
        width: 100%;
        img {
          cursor: pointer;
        }
        .top {
          width: 100%;
          img {
            border-radius: 5px;
          }
        }
        .icon {
          .item {
            float: left;
            padding: 10px 20px;
            width: 33%;
            cursor: pointer;
            img {
              margin-bottom: 5px;
            }
            p {
              text-align: center;
              color: #333;
            }
          }
        }
        .service {
          margin-bottom: 15px;
          img {
            border-radius: 5px;
          }
        }
        .map {
          p {
            font-size: 16px;
            line-height: 40px;
            color: #006ebc;
            font-weight: bold;
            text-indent: 5px;
          }
          img {
            width: 340px;
            border-radius: 5px;
          }
        }
      }
    }
    .title {
      font-size: 16px;
      color: #006ebc;
    }
  }
  .link {
    width: 1200px;
    margin: 0 auto;
    .links {
      .text {
        font-size: 20px;
        line-height: 54px;
        float: left;
      }
      .img {
        margin-left: 15px;
        cursor: pointer;
        width: 196px;
        height: 54px;
      }
    }
  }
}
</style>

<style>
.el-card__header {
  background-color: #f9f9f9;
}
</style>