import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Index from '../views/homepage/Index.vue'

//避免路由冗余导航
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routerReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function (location) {
  return routerReplace.call(this, location).catch(error => error)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    children: [
      {
        path: '/',
        component: Index
      },
      {
        path: 'introduce',
        name: 'introduce',
        component: () => import('../views/navpage/Introduce.vue'),
      },
      {
        path: 'message',
        name: 'message',
        component: () => import('../views/navpage/Message.vue'),
      },
      {
        path: 'serve',
        name: 'serve',
        component: () => import('../views/navpage/Serve.vue'),
      },
      {
        path: 'notice',
        name: 'notice',
        component: () => import('../views/navpage/Notice.vue'),
      },
      {
        path: 'callus',
        name: 'callus',
        component: () => import('../views/homepage/Callus.vue'),
      },
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
