import request from "@/utils/request";
import qs from 'qs'
import { Message, Loading } from 'element-ui'

//获取全部列表
function GetAllList() {
    return new Promise((resolve, reject) => {
        const loading = Loading.service({
            lock: true,
            text: "正在加载",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
        });
        request.get('/api/Category/listall').then(res => {
            loading.close()
            if (res.data.code == 200) {
                resolve(res)
            } else {
                Message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 1500
                })
            }
        }).catch(err => {
            reject(err)
        })
    })
}

//获取下级菜单
function GetNavList(data) {
    return new Promise((resolve, reject) => {
        const loading = Loading.service({
            lock: true,
            text: "正在加载",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
        });
        request.get('/api/Category/list?' + qs.stringify(data)).then(res => {
            loading.close()
            if (res.data.code == 200) {
                resolve(res)
            } else {
                Message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 1500
                })
            }
        }).catch(err => {
            reject(err)
        })
    })
}

//获取企业介绍列表
function GetIntTable(data) {
    return new Promise((resolve, reject) => {
        const loading = Loading.service({
            lock: true,
            text: "正在加载",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
        });
        request.get('/api/About/lists?' + qs.stringify(data)).then(res => {
            loading.close()
            if (res.data.code == 200) {
                resolve(res)
            } else {
                Message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 1500
                })
            }
        }).catch(err => {
            reject(err)
        })
    })
}

//获取企业介绍详情页
function GetIntDetails(data) {
    return new Promise((resolve, reject) => {
        const loading = Loading.service({
            lock: true,
            text: "正在加载",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
        });
        request.get('/api/About/index?' + qs.stringify(data)).then(res => {
            loading.close()
            if (res.data.code == 200) {
                resolve(res)
            } else {
                Message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 1500
                })
            }
        }).catch(err => {
            reject(err)
        })
    })
}

//获取企业信息列表
function GetMsgTable(data) {
    return new Promise((resolve, reject) => {
        const loading = Loading.service({
            lock: true,
            text: "正在加载",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
        });
        request.get('/api/Article/lists?' + qs.stringify(data)).then(res => {
            loading.close()
            if (res.data.code == 200) {
                resolve(res)
            } else {
                Message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 1500
                })
            }
        }).catch(err => {
            reject(err)
        })
    })
}

//获取企业信息详情页
function GetMsgDetails(data) {
    return new Promise((resolve, reject) => {
        const loading = Loading.service({
            lock: true,
            text: "正在加载",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
        });
        request.get('/api/Article/index?' + qs.stringify(data)).then(res => {
            loading.close()
            if (res.data.code == 200) {
                resolve(res)
            } else {
                Message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 1500
                })
            }
        }).catch(err => {
            reject(err)
        })
    })
}

//获取企业服务列表
function GetSerTable(data) {
    return new Promise((resolve, reject) => {
        const loading = Loading.service({
            lock: true,
            text: "正在加载",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
        });
        request.get('/api/Service/lists?' + qs.stringify(data)).then(res => {
            loading.close()
            if (res.data.code == 200) {
                resolve(res)
            } else {
                Message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 1500
                })
            }
        }).catch(err => {
            reject(err)
        })
    })
}

//获取企业服务详情页
function GetSerDetails(data) {
    return new Promise((resolve, reject) => {
        const loading = Loading.service({
            lock: true,
            text: "正在加载",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
        });
        request.get('/api/Service/index?' + qs.stringify(data)).then(res => {
            loading.close()
            if (res.data.code == 200) {
                resolve(res)
            } else {
                Message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 1500
                })
            }
        }).catch(err => {
            reject(err)
        })
    })
}

//获取企业公告列表
function GetNotTable(data) {
    return new Promise((resolve, reject) => {
        const loading = Loading.service({
            lock: true,
            text: "正在加载",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
        });
        request.get('/api/Notice/lists?' + qs.stringify(data)).then(res => {
            loading.close()
            if (res.data.code == 200) {
                resolve(res)
            } else {
                Message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 1500
                })
            }
        }).catch(err => {
            reject(err)
        })
    })
}

//获取企业公告详情页
function GetNotDetails(data) {
    return new Promise((resolve, reject) => {
        const loading = Loading.service({
            lock: true,
            text: "正在加载",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
        });
        request.get('/api/Notice/index?' + qs.stringify(data)).then(res => {
            loading.close()
            if (res.data.code == 200) {
                resolve(res)
            } else {
                Message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 1500
                })
            }
        }).catch(err => {
            reject(err)
        })
    })
}

export { GetAllList, GetNavList, GetIntTable, GetIntDetails, GetMsgDetails, GetMsgTable, GetSerTable, GetSerDetails, GetNotTable, GetNotDetails }