<template>
  <div class="all">
    <div class="hometop">
      <div class="homeheader clearfix">
        <img src="../assets/img/tablogin.png" @click="$router.push('/')" />
        <div class="homeright">
          <p>
            <i class="el-icon-phone"></i>
            服务热线：0377-62125218
          </p>
          <el-input
            v-model="input"
            size="mini"
            placeholder="请输入内容"
            class="input"
          >
            <el-button
              icon="el-icon-search"
              size="mini"
              slot="append"
            ></el-button>
          </el-input>
        </div>
      </div>
    </div>
    <div class="homenav">
      <div class="content clearfix">
        <div class="nav-item" @click="$router.push('/')">首页</div>
        <div
          class="nav-item"
          v-for="item in list"
          :key="item.id"
          @click="routerPush(item.name)"
        >
          {{ item.name }}
          <div class="children" v-if="item.childrens">
            <p
              v-for="(each, index) in item.childrens"
              :key="each.id"
              @click.stop="routerPush(item.name, index, each)"
            >
              {{ each.name }}
            </p>
          </div>
        </div>
        <div class="nav-item" @click="$router.push('/callus')">联系我们</div>
      </div>
    </div>
    <div class="banner">
      <el-carousel :interval="5000" height="450px" arrow="always">
        <el-carousel-item v-for="item in banner" :key="item.id">
          <img
            :src="item.imgUrl"
            width="100%"
            height="100%"
            @click="bannerLink(item)"
          />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="main">
      <router-view :key="$route.fullPath" />
    </div>
    <div class="homebottom">
      <div class="footerbox clearfix">
        <div class="text">
          <p>
            版权所有：邓州市自来水有限公司&ensp;豫ICP备18025384号-2&ensp;地址：邓州市交通路北段
          </p>
          <p>公司电话：0377-62129062&ensp;邮编：474150</p>
          <p>服务热线：0377-62125218</p>
          <a
            href="https://beian.mps.gov.cn/#/query/webSearch?code=41138102000277\"
            rel="noreferrer"
            target="_blank"
            >豫公网安备41138102000277</a
          >
        </div>
        <div class="qrcode">
          <img src="../assets/img/qrcode.jpg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetAllList } from "@/api/public";
import { GetAdList } from "@/api/home";
export default {
  name: "Home",
  data() {
    return {
      input: "",
      list: [],
      banner: [],
    };
  },
  created() {
    this.GetNavList();
    this.getBanner();
  },
  methods: {
    //获取导航菜单数据
    GetNavList() {
      GetAllList()
        .then((res) => {
          //console.log(res);
          let list = res.data.data;
          this.list = list.slice(0, 4);
          //console.log(this.list);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取轮播图
    getBanner() {
      let data = { p: 1 };
      GetAdList(data)
        .then((res) => {
          //console.log(res);
          this.banner = res.data.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //导航栏路由跳转
    routerPush(name, index, each) {
      if (each.jumpUrl && each.typeName == "链接类") {
        window.open(each.jumpUrl);
      } else if (name == "企业介绍") {
        this.$router.push({ path: "/introduce", query: { index: index } });
      } else if (name == "营商环境") {
        this.$router.push({ path: "/message", query: { index: index } });
      } else if (name == "企业服务") {
        this.$router.push({ path: "/serve", query: { index: index } });
      } else if (name == "企业公告") {
        this.$router.push({ path: "/notice", query: { index: index } });
      }
    },
    bannerLink(item) {
      //console.log(item);
      if (item.jumpUrl) {
        window.open(item.jumpUrl);
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.all {
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding-bottom: 186px;
  .hometop {
    width: 1200px;
    margin: 0 auto;
    .homeheader {
      padding: 20px 0;
      img {
        height: 55px;
        float: left;
        cursor: pointer;
      }
      .homeright {
        float: right;
        p {
          color: #006ebc;
          font-size: 14px;
          line-height: 2;
        }
        .input {
          float: left;
          width: 200px;
        }
      }
    }
  }
  .homenav {
    width: 100%;
    height: 48px;
    font-size: 16px;
    color: #fff;
    background-color: #006ebc;
    .content {
      width: 1200px;
      margin: 0 auto;
      .nav-item {
        float: left;
        width: 16.6%;
        line-height: 48px;
        text-align: center;
        cursor: pointer;
        position: relative;
        .children {
          z-index: 999;
          display: none;
          width: 199px;
          background-color: #fff;
          color: #333;
          border: none;
          position: absolute;
          top: 48px;
          left: 0;
          p {
            border: none;
          }
          p:hover {
            background-color: #2482c5;
            color: #fff;
          }
        }
      }
      .nav-item:hover {
        background-color: #2482c5;
      }
      .nav-item:hover .children {
        display: block;
      }
    }
    .content :nth-child(even) {
      border-left: 1px solid #2482c5;
      border-right: 1px solid #2482c5;
    }
    .content :nth-child(1) {
      border-left: 1px solid #2482c5;
    }
  }
  .banner {
    width: 100%;
    height: 450px;
    img {
      cursor: pointer;
    }
  }
  .main {
    width: 1200px;
    margin: 0 auto;
    padding: 15px 0;
  }
  .homebottom {
    position: absolute;
    left: 0;
    bottom: 0;
    padding-top: 65px;
    width: 100%;
    height: 186px;
    background: url(../assets/img/footbg.jpg) no-repeat center center;
    .footerbox {
      width: 1200px;
      margin: 0 auto;
      padding: 0 15px;
      .text {
        float: left;
        p {
          font-size: 14px;
          line-height: 30px;
          color: #333333;
        }
      }
      .qrcode {
        float: right;
        img {
          width: 90px;
        }
      }
    }
  }
}
</style>

<style>
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>