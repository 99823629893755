import request from "@/utils/request";
import qs from 'qs'
import { Message } from 'element-ui'

//获取公司信息列表
function GetMsgList(data) {
    return new Promise((resolve, reject) => {
        request.get('/api/Article/lists?' + qs.stringify(data)).then(res => {
            if (res.data.code == 200) {
                resolve(res)
            } else {
                Message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 1500
                })
            }
        }).catch(err => {
            reject(err)
        })
    })
}

//获取公司信息图片列表
function GetImgList(data) {
    return new Promise((resolve, reject) => {
        request.get('/api/Article/imglists?' + qs.stringify(data)).then(res => {
            if (res.data.code == 200) {
                resolve(res)
            } else {
                Message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 1500
                })
            }
        }).catch(err => {
            reject(err)
        })
    })
}

//获取公司服务列表
function GetServeList(data) {
    return new Promise((resolve, reject) => {
        request.get('/api/Article/lists?' + qs.stringify(data)).then(res => {
            if (res.data.code == 200) {
                resolve(res)
            } else {
                Message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 1500
                })
            }
        }).catch(err => {
            reject(err)
        })
    })
}

//获取公司公告列表
function GetNoticeList(data) {
    return new Promise((resolve, reject) => {
        request.get('/api/Notice/lists?' + qs.stringify(data)).then(res => {
            if (res.data.code == 200) {
                resolve(res)
            } else {
                Message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 1500
                })
            }
        }).catch(err => {
            reject(err)
        })
    })
}

//获取广告列表
function GetAdList(data) {
    return new Promise((resolve, reject) => {
        request.get('/api/Ad/list?' + qs.stringify(data)).then(res => {
            if (res.data.code == 200) {
                resolve(res)
            } else {
                Message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 1500
                })
            }
        }).catch(err => {
            reject(err)
        })
    })
}

//获取友情链接列表
function GetLinkList(data) {
    return new Promise((resolve, reject) => {
        request.get('/api/Links/list?' + qs.stringify(data)).then(res => {
            if (res.data.code == 200) {
                resolve(res)
            } else {
                Message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 1500
                })
            }
        }).catch(err => {
            reject(err)
        })
    })
}

export { GetMsgList, GetImgList, GetServeList, GetNoticeList, GetAdList, GetLinkList }