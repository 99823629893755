import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//公共样式
import '@/assets/css/index.css'
//富文本编辑器样式
import '@/assets/css/quill.snow.css'
//富文本编辑器样式
//element ui
import 'element-ui/lib/theme-chalk/index.css';
import { Tag, Descriptions, DescriptionsItem, Pagination, Empty, Button, Input, Menu, MenuItem, Submenu, Carousel, CarouselItem, Card, Tabs, TabPane, Divider } from 'element-ui';
Vue.use(Button)
Vue.use(Input)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Submenu)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Card)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Divider)
Vue.use(Empty)
Vue.use(Pagination)
Vue.use(Descriptions)
Vue.use(DescriptionsItem)
Vue.use(Tag)
//element ui

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
